import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import { useState } from 'react';

import createApolloClient from '../lib/apollo-client';

interface AppState {
	mobileNavOpen: boolean;
	mobileFilterOpen: boolean;
}

interface AppContextInterface extends AppState {
	toggleMobileNav: (open: boolean) => void;
	toggleMobileFilter: (open: boolean) => void;
}

const stub = (): never => {
	throw new Error('You likely forgot to wrap your component in a <AppProvider>');
};

const initialContext: AppContextInterface = {
	mobileNavOpen: false,
	mobileFilterOpen: false,
	toggleMobileNav: stub,
	toggleMobileFilter: stub
};

const AppContext = React.createContext<AppContextInterface>(initialContext);

export const useAppContext = (context = AppContext) => {
	return React.useContext(context) as AppContextInterface;
};

interface AppProviderProps {
	children: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
	const client = createApolloClient();
	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [mobileFilterOpen, setMobileFilterOpen] = useState(false);

	const toggleMobileFilter = React.useCallback((open: boolean) => {
		setMobileFilterOpen(open);
	}, []);

	const toggleMobileNav = React.useCallback((open: boolean) => {
		setMobileNavOpen(open);
	}, []);

	const contextValue = React.useMemo(() => {
		return {
			mobileNavOpen,
			mobileFilterOpen,
			toggleMobileNav,
			toggleMobileFilter
		};
	}, [mobileNavOpen, mobileFilterOpen, toggleMobileNav, toggleMobileFilter]);

	return (
		<ApolloProvider client={client}>
			<AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
		</ApolloProvider>
	);
};

export default AppProvider;
