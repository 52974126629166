'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';

import AppProvider from '../providers/app';

interface ProvidersProps {
	children: React.ReactNode;
}

const queryClient = new QueryClient();

export default function Providers({ children }: ProvidersProps) {
	return (
		<AppProvider>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</AppProvider>
	);
}
