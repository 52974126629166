'use client';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import {
	FRESHDESK_CHAT_BOT_HOST,
	FRESHDESK_CHAT_BOT_TOKEN,
	FRESHDESK_SCRIPT_ID,
	FRESHDESK_SCRIPT_SRC,
	WHITE_LISTED_PAGES
} from '@/components/RenderScripts/FreshdeskChatBot/constants';

const FreshdeskChatBot = () => {
	const pathname = usePathname();
	function initFreshChat() {
		if (window.fcWidget) {
			window.fcWidget.init({
				token: FRESHDESK_CHAT_BOT_TOKEN,
				host: FRESHDESK_CHAT_BOT_HOST
			});
		}
	}
	// IMPORTANT: There are methods: .hide, .show, if .destroy stops working
	function destroyFreshChat() {
		if (window.fcWidget) {
			window.fcWidget.destroy(); // Properly clean up the widget
		}
	}
	function loadFreshdeskScript() {
		if (!document.getElementById(FRESHDESK_SCRIPT_ID)) {
			const script = document.createElement('script');
			script.id = FRESHDESK_SCRIPT_ID;
			script.async = true;
			script.src = FRESHDESK_SCRIPT_SRC;
			script.onload = initFreshChat;
			document.head.appendChild(script);
		} else {
			initFreshChat(); // Script is already loaded; initialize Freshdesk
		}
	}
	useEffect(() => {
		if (pathname && WHITE_LISTED_PAGES.some((whitelistedPage) => pathname.startsWith(whitelistedPage))) {
			loadFreshdeskScript(); // Ensure script is loaded when the component mounts
		} else {
			// Properly destroy the widget and remove script
			destroyFreshChat();
		}
	}, [pathname]);
	return null;
};
export default FreshdeskChatBot;
