'use client';

import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function ScrollToTop() {
	const [isVisible, setIsVisible] = useState(false);

	const pathname = usePathname();
	const isSearchPage = pathname === '/search';

	const toggleVisibility = () => {
		if (window.scrollY > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const hasHiddenClass = () => {
		if (!isVisible) return 'hidden';

		// For Search page, we hide this button on mobile
		if (isSearchPage) return 'hidden md:inline-flex';
		return;
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		<div className="z-100 fixed bottom-3 left-1/2 -translate-x-1/2 sm:bottom-3 sm:left-auto sm:right-9 sm:translate-x-0">
			<button
				onClick={scrollToTop}
				className={`inline-flex h-9 items-center justify-center rounded-lg bg-blue-500 px-4 py-2.5 text-base font-semibold leading-5 text-white 
        transition-all duration-300 ease-[cubic-bezier(0.4,0,0.2,1)]
        hover:bg-blue-700
        ${hasHiddenClass()}`}
			>
				<span>
					<FontAwesomeIcon className="mr-2" icon={faArrowToTop} />
				</span>
				Back to Top
			</button>
		</div>
	);
}
