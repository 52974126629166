import(/* webpackMode: "eager" */ "/app/apps/tournament/src/app/_components/scroll-to-top.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/app/globals.css");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/app/providers.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/ConnectDUPRModal/ConnectDUPRModal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/ImpersonationBar.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/components/RenderScripts/FreshdeskChatBot/FreshdeskChatBot.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/lib/SessionProvider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/permissions/AuthProvider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/tournament/src/providers/ImpressionProvider.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.6.0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.1.4_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__reac_ezcehdcpsdexqqdmukara5piiq/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.1.4_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__reac_ezcehdcpsdexqqdmukara5piiq/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.1.4_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__reac_ezcehdcpsdexqqdmukara5piiq/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@pickleballinc+react-ui@1.47.4_@floating-ui+react@0.25.4_react-dom@18.3.1_react@18.3.1__react_t2tb63kvgmj3wfb3g24lhaeuw4/node_modules/@pickleballinc/react-ui/stylesheets/bundle.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/packages/ui/src/globals.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-date-range@2.0.1_date-fns@4.1.0_react@18.3.1/node_modules/react-date-range/dist/styles.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-date-range@2.0.1_date-fns@4.1.0_react@18.3.1/node_modules/react-date-range/dist/theme/default.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.4_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}")